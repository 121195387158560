import * as React from "react";
import { IntlProvider } from "react-intl";
import { graphql, navigate } from "gatsby";
import { useEffect } from "react";
import _ from "lodash";

import { Helmet } from "react-helmet";
import SeoStructure from "../../components/seo-structure";
import Layout from "../../components/layout";
import Posts from "../../components/posts";

import LocalesContext from "../../context/LocalesContext";
import messages from "../../../i18n-translations.json";

const IndexPage = ({ data }) => {
  const allWpCategory = data?.allWpCategory;

  useEffect(() => {
    if (_.isEmpty(allWpCategory)) return navigate("/");
  }, [allWpCategory]);

  if (_.isEmpty(allWpCategory)) return null;

  return (
    <>
      <Posts allWpCategory={allWpCategory} />
    </>
  );
};

const MainPage = (props) => {
  const site = props.data.site;
  const language = props.data.allWpCategory.nodes[0]?.language.name;
  
  return (
    <IntlProvider locale={"pt"} messages={messages["pt"]}>
      <LocalesContext.Provider
        value={{
          slug: "",
          avaiableLangs: ["en", "da", "de", "es", "fr", "it", "nl", "nb", "pl", "pt", "fi", "sv", "tr", "el", "ru", "ja", "ko"],
          pageNumber: 0,
        }}
      >
        <Layout props={props}>
          <Helmet>
            <script
              async
              src="https://boot.pbstck.com/v1/tag/8c704138-0cf5-43be-9bf5-416e674a06d9"
              type="text/javascript"
            />
          </Helmet>
          <SeoStructure
            title={messages["pt"].seo.title}
            description={messages["pt"].seo.description}
            slug="pt"
          >
            <script type="application/ld+json">{
              JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'WebPage',
                '@id': `${!site.id ? '' : site.id}`,
                'name': `${!process.env.GATSBY_SITE_NAME ? '' : process.env.GATSBY_SITE_NAME}`,
                'url': `${!process.env.GATSBY_SITE_URL ? '' : process.env.GATSBY_SITE_URL}`,
                'inLanguage': `${!language ? '' : language}`,
                'isPartOf': {
                  '@type': 'WebPage',
                  '@id': `${!site.id ? '' : site.id}`,
                  'url': `${!process.env.GATSBY_SITE_URL ? '' : process.env.GATSBY_SITE_URL}`,
                  'name': `${!process.env.GATSBY_SITE_NAME ? '' : process.env.GATSBY_SITE_NAME}`,
                  'description': `${!site.siteMetadata.description ? '' : site.siteMetadata.description}`,
                  'inLanguage': `${!language ? '' : language}`,
                },
                'potentialAction': {
                  '@type': 'ReadAction',
                  'target': {
                    '@type': 'EntryPoint',
                    'urlTemplate': `${!process.env.GATSBY_SITE_URL ? '' : process.env.GATSBY_SITE_URL}`
                  }
                }
              })}
            </script>
          </SeoStructure>
          <IndexPage data={props.data} />
        </Layout>
      </LocalesContext.Provider>
    </IntlProvider>
  );
};

export default MainPage;

export const query = graphql`
  query ($defaultCategorySlugs: [String]) {
    allWpCategory(
      filter: {
        slug: { nin: $defaultCategorySlugs }
        count: {gte: 4}
        language: { slug: { eq: "pt" } }
      }
    ) {
      nodes {
        name
        slug
        posts {
          nodes {
            id
            slug
            title
            excerpt
            originalSlug
            featuredImage {
              node {
                title
                mediaItemUrl
                mediaDetails {
                  filteredSizes {
                    name
                    sourceUrl
                  }
                  sizes {
                    name
                  }
                }
              }
            }
            seo {
              title
              metaDesc
              focuskw
              metaKeywords
              opengraphTitle
              opengraphDescription
              opengraphImage {
                altText
                sourceUrl
                srcSet
              }
              twitterTitle
              twitterDescription
              twitterImage {
                altText
                sourceUrl
                srcSet
              }
              canonical
              cornerstone
              schema {
                articleType
                pageType
                raw
              }
            }
          }
        }
        language {
          slug
          name
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
      id
    }
  }
`;
